<script>
import ModalManageContent from '@/components/contents/ModalManageContent'
import ModalManageList from '@/components/lists/ModalManageList'
import Loading from './Loading.vue'
export default {
  components: {
    ModalManageList,
    ModalManageContent,
    ContentTypesActionOptions: () => import('../contents/ContentTypesActionOptions'),
    Loading
  },
  name: 'ModalSelectAction',
  data () {
    return {
      showModalContent: false,
      showModalList: false,
      dialog: false,
      isLoading: false
    }
  },
  props: {
    icon: { type: String, default: '' },
    btnText: { type: String, default: '' },
    color: { type: String, default: '' }
  },
  computed: {
    contentTypes () {
      return this.$store.getters.getContentTypes
    }
  },
  methods: {
    getContentTypes () {
      this.isLoading = true
      this.synchronizeUser().then(() => {
        if (this.contentTypes.length === 0) {
          this.$store.dispatch('attemptGetContentTypes').then(() => {
            this.isLoading = false
          })
        } else {
          this.isLoading = false
        }
      })
    },
    closeModal (value) {
      this.showModalContent = value
      this.dialog = false
    },
    manageList () {
      this.showModalList = true
    }
  },
  created () {
    // DESATIVADO PARA MANUTENÇÃO
    // if (!this.getUserInfo || !this.getUserInfo.skills) {
    //   this.$store.dispatch('attemptGetMyselfSkills')
    // }
  }
}
</script>

<template>
  <div class="workspace-options--container">
    <v-menu content-class="workspace-options--wrapper" bottom right transition="none" z-index="125">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          dark
          height="44"
          max-width="44"
          min-width="44"
          v-bind="attrs"
          v-on="on"
          elevation="0"
          @click="getContentTypes"
        > <v-icon v-if="icon">{{icon}}</v-icon>
        </v-btn>
      </template>
      <v-card class="content-options--container">
        <Loading v-if="isLoading"/>
        <v-container v-else class="content-options--wrapper">
          <div class="menu-add-content--wrapper">
            <h4 class="text-left neutral-dark mb-1 h7">
              {{ `${$t('global:add')} ${$t('global:contents')}` }}
            </h4>
            <p class="text-left subtitle--1 text-greyed">
              <span>{{ $t('content.modal:description') }}</span>
            </p>
            <content-types-action-options  :contentTypes="contentTypes" @closeModalSelectAction="closeModal"/>
          </div>
          <div class="h-divider my-6 thick neutral-light"></div>
          <div class="menu-add-list--wrapper">
            <h4 class="text-left neutral-dark mb-2 h7">
              {{ `${$t('global:create')} ${$t('global:lists')}` }}
            </h4>
            <p class="text-left subtitle--1 text-greyed">
              <span>{{ $t('list.modal:description') }}</span>
            </p>
            <v-btn outlined class="text-left btn transform-unset" color="#1200D3" @click="manageList">
              <v-icon class="mr-1">mdi-text-box</v-icon> <span class="bold ml-1">{{ $t('list.modal:add') }}</span>
            </v-btn>
          </div>
        </v-container>
      </v-card>
    </v-menu>
    <modal-manage-content v-if="showModalContent" :initialContent="showModalContent" instanced @close="showModalContent = false"/>
    <modal-manage-list v-if="showModalList" instanced @close="showModalList = false"/>
  </div>
</template>
<style lang="scss">
.content-options--container {
  width: 400px;
  .content-options--wrapper {
    width: 400px;
    max-width: 100%;
  }
  .menu-add-list--wrapper,
  .menu-add-skills--wrapper {
    text-align: left;
  }
}
@media only screen and (max-width: 768px) {
  .content-options--container.container {
    padding: 24px 16px;
  }
}
</style>
